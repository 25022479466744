import { Routes } from "@models/routes";
import { lazy } from "react";

const UserStudyPage = lazy(() =>
  import("@pages/company/user-study/index").then((p) => ({
    default: p.UserStudy,
  }))
);

const AboutUsPage = lazy(() => import("@pages/company/AboutUs").then((p) => ({ default: p.AboutUs })));

const CaseStudyPage = lazy(() => import("@pages/company/CaseStudies").then((p) => ({ default: p.CaseStudies })));

const OurApproachPage = lazy(() => import("@pages/company/Approach").then((p) => ({ default: p.Approach })));

const ReferralSchemePage = lazy(() => import("@pages/company/ReferralScheme").then((p) => ({ default: p.ReferralScheme })));

const TestimonialsPage = lazy(() => import("@pages/company/Boiler").then((p) => ({ default: p.Boiler })));

export const CompanyRoutes: Routes = [
  {
    path: "/about-us",
    component: AboutUsPage,
    isWhite: true,
  },
  {
    path: "/case-studies",
    component: CaseStudyPage,
    children: [
      {
        path: ":user",
        component: UserStudyPage,
      },
    ],
  },
  {
    path: "/our-approach",
    component: OurApproachPage,
  },
  {
    path: "/solar-pv-referral-scheme",
    component: ReferralSchemePage,
  },
  {
    path: "/testimonials",
    component: TestimonialsPage,
  },
];
